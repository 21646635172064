import { useAuth } from "@clerk/clerk-react";
import { Grid, Slide, useTheme } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import {
  ENTER_DELAY,
  EXIT_DELAY,
  SlideAnimationContext,
  SlideAnimationProvider,
} from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BentoBrand, SearchParams, VAStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Loading from "components/Loading";
import { PAGE_VISITED } from "constants/trackingProps";
import { fetcherAuth } from "utils/api";
import { getSource, identifyUser, trackEvent } from "utils/tracking";

import { getIncomeLevel } from "../VAOnboarding/IncomeForm/helpers";
import AboutForm from "./AboutForm";
import { getUserType } from "./AboutForm/helpers";
import CategoryForm from "./CategoryForm";
import EmailBrandForm from "./EmailBrandForm";
import EngagementForm from "./EngagementForm";
import ImproveCurrentPitch from "./ImproveCurrentPitch";
import PartnershipForm from "./PartnershipForm";
import PitchEmailForm from "./PitchEmailForm";
import PitchingGoal from "./PitchingGoal";
import PitchingHighlights from "./PitchingHighlights";
import PortfolioForm from "./PortfolioForm";
import RecommendationForm from "./RecommendationForm";
import SelectBrandForm from "./SelectBrandForm";
import StandOutForm from "./StandOutForm";
import TemplateForm from "./TemplateForm";
import { onboardingNavigate } from "./helper";
import styles from "./styles";

const OnboardingInfluencer = () => {
  const {
    currentOrg,
    currentUser,
    setCurrentUser,
    setCurrentOrg,
    setUserNotifications,
    setUserIntegrations,
    setEmailSettings,
    setHideCityDialog,
    setProfile,
    setIncludedBentoSearchFilterIds,
    setExcludedBentoSearchFilterIds,
    includedBentoSearchFilterIds,
    excludedBentoSearchFilterIds,
  } = useContext(OrganizationUserContext);
  const theme = useTheme();
  const { setSubscription, subscription } = useContext(SubscriptionContext);
  const [searchParams] = useSearchParams();
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);

  const { slideOutUp, slideInUp, slideOutDown, slideInDown, slideProps } =
    useContext(SlideAnimationContext);

  const referralCode = searchParams.get(SearchParams.REFERRAL_CODE);

  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [fetchUserLoading, setFetchUserLoading] = useState(true);
  const [firstDraftLoaded, setFirstDraftLoaded] = useState(false);
  const [recommendedBrands, setRecommendedBrands] = useState<BentoBrand[]>([]);
  const [cursor, setCursor] = useState<number[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>();
  const [excludeIncludeFilterHasChanged, setExcludeIncludeFilterHasChanged] =
    useState(false);

  const source = getSource();

  const [onboardingForm, setOnboardingForm] = useState<Map>({
    name: currentUser?.name || "",
    location: "",
    brands: [],
    wishlistBrands: [],
    categories: [],
    isUgcCreator: null,
    isInfluencer: null,
    incomeLevel: "",
    pastBrandValidation: null,
    primarySocialMediaPlatform: "instagram",
    defaultTemplateHighlights: [],

    // These fields are auto-filled when user sign up
    email: "",
    organizations: [],
    organizationId: "",
    organizationName: "",
    type: "influencer",
  });

  const fetchUserInfo = async () => {
    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);
      setIncludedBentoSearchFilterIds(res.includedBentoSearchFilterIds);
      setExcludedBentoSearchFilterIds(res.excludedBentoSearchFilterIds);
      if (res.organizationProfile) {
        const copy = { ...res.organizationProfile };
        setProfile(copy);
      }

      setFetchUserLoading(false);

      const isInVaOnboardingFlow =
        res.organization.vaStatus === VAStatus.pending ||
        (res.organization.signedUpForVa &&
          res.organization.vaStatus !== VAStatus.not_interested &&
          res.organization.vaStatus !== VAStatus.disqualified);

      // Redirect to dashboard if they already onboard.
      if (isInVaOnboardingFlow) {
        navigate(`/${routes.onboardingVaInfluencer}${location.search}`);
        return;
      }

      const hasOnboarded = Boolean(res?.organizationProfile?.hasOnboarded);
      const hasTemplate = Boolean(res?.organizationProfile?.hasTemplate);

      // Edge case: user has not sent an email, but want to book an onboarding call
      // then after they checkout, redirect back to dashboard instead of making
      // them go to onboarding even if they hasn't send an email.
      const isCheckingOut = searchParams.get("checkout");

      // Edge case: user who subscribed to monthly VA and click on the Connect to Google link
      const openIntegrationDialog =
        searchParams.get("open_integration_dialog") === "true";

      // Edge case: user who subscribed to monthly VA and click on Import from Gmail link.
      const openImportGmail =
        searchParams.get("ref") === "va_subscribed_monthly";

      if (openImportGmail && !openIntegrationDialog) {
        navigate(`/${routes.trackingView}${location.search}`);
        return;
      }

      if (hasOnboarded || isCheckingOut || openIntegrationDialog) {
        navigate(`/${routes.brands}/all${location.search}`);
        return;
      }

      setHideCityDialog(true);
      if (res.organizationProfile) {
        setOnboardingForm((prev) => ({
          ...prev,
          ...res.organizationProfile,
          name: res.organizationUser.name,
          pitchEmail: res.organizationProfile.pitchEmail,
          userType: getUserType(res.organizationProfile),
          incomeLevel: getIncomeLevel(res.organizationProfile),
        }));
      } else {
        setOnboardingForm((prev) => ({
          ...prev,
          name: res.organizationUser.name,
        }));
      }

      slideInUp();

      if (!res?.organizationProfile) {
        navigate(`/${routes.aboutOnboarding}${location.search}`);
        return;
      }

      if (hasTemplate) {
        const hasWishlistBrands =
          res.organizationProfile?.wishlistBrands &&
          res.organizationProfile.wishlistBrands.length > 0;

        if (hasWishlistBrands) {
          navigate(`/${routes.selectBrandOnboarding}${location.search}`);
        } else {
          navigate(`/${routes.recommendationOnboarding}${location.search}`);
        }
        return;
      }

      if (
        !new RegExp(`${routes.onboardingInfluencer}/?$`).test(location.pathname)
      ) {
        return;
      }

      const hasCategories =
        res.organizationProfile.categories.length > 0 ||
        res.organizationProfile.selectedCategories.length > 0;
      if (!hasCategories) {
        navigate(`/${routes.categoryOnboarding}${location.search}`);
        return;
      }
      navigate(`/${routes.pitchOnboarding}${location.search}`);
    } catch (error) {
      handleClerkUserSignUp();
    }
  };

  const handleClerkUserSignUp = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/auth/signup`,
        "POST",
        {},
        {
          referralCode,
          source: referralCode ? "referral" : source,
        },
      );
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setOnboardingForm((prev) => ({
        ...prev,
        name: res.organizationUser.name,
      }));

      setHideCityDialog(true);
      navigate(`/${routes.aboutOnboarding}${location.search}`);
      slideInUp();
    } catch (error) {
      signOut();
      navigate(`/${routes.signIn}`);
      setErrorAlert(error);
    } finally {
      setFetchUserLoading(false);
    }
  };

  const saveOnboardingForm = async (onboardingForm: Map, route: string) => {
    setLoading(true);

    if (excludeIncludeFilterHasChanged) {
      handleSaveFilters();
    }

    const onboardingFormReq = {
      ...onboardingForm,
      source,
      route,
    };
    if (route) {
      trackEvent("Onboarding Form - Next Button Pressed", { route });
    }
    await slideOutUp();

    const transferredFromVa =
      currentOrg?.vaStatus === VAStatus.not_interested &&
      onboardingForm?.wishlistBrands?.length > 0;

    navigate(
      `/${onboardingNavigate(
        location.pathname,
        onboardingForm,
        "next",
        transferredFromVa,
      )}${location.search}`,
    );

    slideInUp();

    try {
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding`,
        "PUT",
        {},
        onboardingFormReq,
      );

      setOnboardingForm((prev) => ({
        ...prev,
        ...organizationProfile,
      }));
      setProfile({ ...organizationProfile });
    } catch (error) {
      await slideOutDown();
      slideInDown();
      navigate(`${route}${location.search}`);

      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandRecommendations = async (restart: boolean = false) => {
    setLoading(true);
    setSelectedBrandId(undefined);
    if (restart) {
      setCursor([]);
    }
    try {
      let url = `/api/organization/${currentOrg?.id}/onboarding/brand-recommendations`;
      if (cursor && !restart) {
        url += `?cursor=${JSON.stringify(cursor)}`;
      }
      const res = await fetcherAuth(getToken, url, "GET");
      setCursor(res.cursor);
      setRecommendedBrands(res.bentoBrands);
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to fetch brand recommendations. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const enqueueGenerateRecommendations = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts/generate`,
        "POST",
      );
    } catch (error) {}
  };

  const fetchEmailPreview = async (selectedBrandId: number) => {
    setSelectedBrandId(selectedBrandId);
    const fetchUserRequired = !subscription;
    if (fetchUserRequired) {
      setLoading(true);
      try {
        const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
        setSubscription(res.subscription);
        setUserIntegrations(res.userIntegrations);
        setUserNotifications(res.userNotifications);
        setEmailSettings(res.emailSetting);
        if (res.organizationProfile) {
          const copy = { ...res.organizationProfile };
          setProfile(copy);
        }
      } catch (error) {
        setAlert(
          error?.message ||
            "Unable to fetch brand email. Please reload and try again",
          "error",
        );
        return;
      } finally {
        setLoading(false);
      }
    }
    if (fetchUserRequired && !firstDraftLoaded) {
      setFirstDraftLoaded(true);
    } else {
      handleOpenQuickSendIndividual(selectedBrandId, "Onboarding Form");
    }
  };

  const handleSaveFilters = async () => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/search-filters`,
        "PUT",
        {},
        {
          include: includedBentoSearchFilterIds,
          exclude: excludedBentoSearchFilterIds,
        },
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    // The firstDraftLoaded variable is used if the fetching of user is required before opening
    // quicksend. Since quicksend requires additional state updates to happen first after fetching the user, we need to
    // open it in a useEffect after all the state updates completed
    if (firstDraftLoaded && selectedBrandId) {
      handleOpenQuickSendIndividual(selectedBrandId, "Onboarding Form");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDraftLoaded, selectedBrandId]);

  useEffect(() => {
    fetchUserInfo();
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <>
      <Grid container sx={styles.root}>
        <Slide
          in={slideProps.in}
          direction={slideProps.direction}
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeIn,
          }}
          timeout={{
            enter: ENTER_DELAY,
            exit: EXIT_DELAY,
          }}
        >
          <Grid sx={styles.formContainer} item xs={12}>
            <Routes>
              <Route
                path="/about"
                element={
                  <AboutForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/category"
                element={
                  <CategoryForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/pitch"
                element={
                  <PitchEmailForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/current-pitch"
                element={
                  <ImproveCurrentPitch
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    setLoading={setLoading}
                  />
                }
              />
              <Route
                path="/pitching-goal"
                element={
                  <PitchingGoal
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/highlights"
                element={
                  <PitchingHighlights
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/portfolio"
                element={
                  <PortfolioForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/partnerships"
                element={
                  <PartnershipForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/engagement"
                element={
                  <EngagementForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/template"
                element={
                  <TemplateForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/stand-out"
                element={
                  <StandOutForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/recommendation"
                element={
                  <RecommendationForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    setExcludeIncludeFilterHasChanged={
                      setExcludeIncludeFilterHasChanged
                    }
                  />
                }
              />
              <Route
                path="/select-brand"
                element={
                  <SelectBrandForm
                    onboardingForm={onboardingForm}
                    loading={loading}
                    selectedBrandId={selectedBrandId}
                    setSelectedBrandId={setSelectedBrandId}
                    refreshBrands={fetchBrandRecommendations}
                    recommendedBrands={recommendedBrands}
                    enqueueGenerateRecommendations={
                      enqueueGenerateRecommendations
                    }
                  />
                }
              />
              <Route
                path="/email-brand/:brandId"
                element={
                  <EmailBrandForm
                    loading={loading}
                    fetchEmailPreview={fetchEmailPreview}
                  />
                }
              />
            </Routes>
          </Grid>
        </Slide>
      </Grid>
    </>
  );
};

export default function Onboarding() {
  return (
    <SlideAnimationProvider>
      <OnboardingInfluencer />
    </SlideAnimationProvider>
  );
}
