import { useAuth } from "@clerk/clerk-react";
import { Grid } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import SingleFilter from "./SingleFilter";

interface ExcludeIncludeFiltersProps {
  setExcludeIncludeFilterHasChanged?: Dispatch<SetStateAction<boolean>>;
}

const ExcludeIncludeFilters = ({
  setExcludeIncludeFilterHasChanged,
}: ExcludeIncludeFiltersProps) => {
  const { getToken } = useAuth();
  const {
    currentOrg,
    includedBentoSearchFilterIds,
    excludedBentoSearchFilterIds,
    setIncludedBentoSearchFilterIds,
    setExcludedBentoSearchFilterIds,
    searchFilters,
    setSearchFilters,
  } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);

  const fetchFilters = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-search-filters`,
        "GET",
      );
      setSearchFilters(res.bentoSearchFilters);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(
    () => {
      if (currentOrg?.id && searchFilters?.length === 0) {
        fetchFilters();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id],
  );

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    if (setExcludeIncludeFilterHasChanged) {
      setExcludeIncludeFilterHasChanged(true);
    }
    if (name === "include") {
      setIncludedBentoSearchFilterIds(value);
    } else if (name === "exclude") {
      setExcludedBentoSearchFilterIds(value);
    }
  };

  return (
    <Grid container alignItems="center" gap={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={5.5}>
        <SingleFilter
          values={includedBentoSearchFilterIds || []}
          handleChange={handleChange}
          options={searchFilters}
          name="include"
        />
      </Grid>

      <Grid item xs md>
        <SingleFilter
          values={excludedBentoSearchFilterIds || []}
          handleChange={handleChange}
          options={searchFilters}
          name="exclude"
        />
      </Grid>
    </Grid>
  );
};

export default ExcludeIncludeFilters;
